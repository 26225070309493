import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "フロントエンドエンジニアが UI 設計〜実装で考えていること",
  "date": "2019-03-27T06:38:54.000Z",
  "slug": "entry/2019/03/27/153854",
  "tags": ["medley"],
  "hero": "./2019_03_27.png",
  "heroAlt": "frontend engineer"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`おはこんばんちは、開発本部エンジニアの大岡です。`}</p>
    <p>{`先日、TechLunch という社内勉強会で「フロントエンジニアが UI 設計〜実装で考えていること」という内容で発表しました。UI 設計から実装の細かい話ではなくどういう気持ちで望んでるかという話で、基本的なことしか書いていませんが紹介させていただければと思います。`}</p>
    <h1>{`UI 設計`}</h1>
    <p>{`UI 設計をデザイナーがして、それをエンジニアが実装する場合とエンジニアが単独で UI 設計から実装までする場合があります。今回はそれぞれのケースで、フロントエンドエンジニアがどういうことを考えてるかを紹介します。`}</p>
    <p>{`とその前に、他人と UI について話すときに気をつけてる言葉で分かりやすくまとまっているツイートがあったので紹介します。`}</p>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">自分が他の人に UI デザインの説明をするとき、あまり使わないようにしている言葉がいくつかあるので、それらについて描きました。<br />私の中では、これ系の言葉は、<br />初心者：使いがち<br />中級者：ほぼ使わない<br />上級者：たまに使ってる<br />みたいな印象があります。 <a href="https://t.co/O2URgRCVHe">pic.twitter.com/O2URgRCVHe</a></p>&mdash; シンギュラリティ (@singularity8888) <a href="https://twitter.com/singularity8888/status/1069378693259128834?ref_src=twsrc%5Etfw">December 2, 2018</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <ul>
      <li parentName="ul">{`普通はそうする`}</li>
      <li parentName="ul">{`直感的にわかる`}</li>
      <li parentName="ul">{`使いやすい`}</li>
      <li parentName="ul">{`わかりやすい`}</li>
    </ul>
    <p>{`これらの言葉は誰にとってなのか?やそれまでの経験によって意味合いが変わってきてしまうので対象を明確にしないと相手と話が噛み合わなくなることもあるので気をつけています。`}<strong parentName="p">{`今回何ヶ所か使っていますが実際 UI について話すときは使わないように心がけています`}</strong>{`。`}</p>
    <h1>{`デザイナーから画面仕様をもらう場合`}</h1>
    <p>{`デザイナーが優秀であればあるほど、もらった画面仕様を元に早く手を動かしたくなります。ですがここはグッと堪えます。落ち着いて以下のようなことを考えてます。`}</p>
    <ul>
      <li parentName="ul">{`なぜこの課題がうまれたのか`}</li>
      <li parentName="ul">{`解決したい課題の本質はなんなのか`}</li>
      <li parentName="ul">{`他の解決策はないのか`}</li>
      <li parentName="ul">{`この機能を追加してしまうことにより他に影響がでてしまわないか`}</li>
      <li parentName="ul">{`追加ではなく他の機能を落とすことにより解決できないのか`}</li>
      <li parentName="ul">{`そもそも必要なのか`}</li>
    </ul>
    <p>{`など、この段階で理解できないことはチームで話したりして解決しておきます。次に画面仕様に目を向けまた考えます。`}</p>
    <ul>
      <li parentName="ul">{`似たようなコンポーネントがあったら統一できないか`}</li>
      <li parentName="ul">{`より使いやすくできないのか`}</li>
      <li parentName="ul">{`操作感が他と明らかに違わないか`}</li>
      <li parentName="ul">{`操作に対して予想した動きになっているか`}</li>
      <li parentName="ul">{`表示されている情報に意味があるのか`}</li>
    </ul>
    <p>{`など、画面仕様からは理解できないことや疑問点などはデザイナーとコミュニケーションをとり齟齬がでないようにします。`}</p>
    <p><strong parentName="p">{`手を動かす前にある程度ユーザが解決したい課題を精度高く汲み取り落としこめる`}</strong>{`ように努力してます。`}</p>
    <h1>{`自分で UI 設計をする場合`}</h1>
    <p>{`「デザイナーから画面仕様をもらう場合」に書いたことをまずは考えています。`}</p>
    <p>{`それらを踏まえ、`}<strong parentName="p">{`すでに実装済みの UI で似たようなものがあれば似たように作ります`}</strong>{`。理由としては既にユーザが学習済みの UI のため新たに追加した機能だとしても比較的学習コストが低くなるのではないかと考えるからです。`}</p>
    <p>{`似たような UI がなく新たに作らなければならないときは以下を意識しています。`}</p>
    <ul>
      <li parentName="ul">{`表示する情報の精査`}</li>
      <li parentName="ul">{`表示する情報はリストなのか詳細表示なのか`}</li>
      <li parentName="ul">{`表示する情報に対しての操作はどんなものがあるのか`}</li>
      <li parentName="ul">{`操作に対してのレスポンス・納得感はどうすれば得られるのか`}</li>
      <li parentName="ul">{`エラー表示をどうするのか`}</li>
      <li parentName="ul">{`表示する情報が多すぎて収まり切らない場合どうするのか`}</li>
      <li parentName="ul">{`表示する情報がない場合どうするのか`}</li>
      <li parentName="ul">{`画面サイズ毎に適切に表示できるか`}</li>
      <li parentName="ul">{`状態（クリックやホバーなど）を表現できてるか`}</li>
      <li parentName="ul">{`リストの場合デフォルトの順番は適切なのか`}</li>
    </ul>
    <p>{`などをざっくりノートなどに書き出し詳細を詰めていっています。行き詰まったら迷わずデザイナーに相談しにいきます笑`}</p>
    <h1>{`実装`}</h1>
    <p>{`細かい実装部分というよりはもっと大枠の基本的なことですが紹介します。`}</p>
    <ul>
      <li parentName="ul">{`何も考えずにコピペしない`}
        <ul parentName="li">
          <li parentName="ul">{`修正が大変になるので一箇所になるべくまとめる`}</li>
        </ul>
      </li>
      <li parentName="ul">{`用途にあったコンポーネントを使う`}
        <ul parentName="li">
          <li parentName="ul">{`似てるからと使い回すとコンポーネント修正時に思わぬ変更が加わる可能性があるかも`}</li>
        </ul>
      </li>
      <li parentName="ul">{`コンポーネント間の関係が疎になるようにする`}
        <ul parentName="li">
          <li parentName="ul">{`密接な関係だと気を使い合わなくてはならず修正が大変`}</li>
        </ul>
      </li>
      <li parentName="ul">{`コンポーネントを作成する際に無理に汎用性をもたせない`}
        <ul parentName="li">
          <li parentName="ul">{`修正が困難になり逆に汎用性がなくなる場合が多い`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://redux.js.org/"
        }}>{`Redux`}</a>{`に管理させるステートの粒度は適切か`}</li>
      <li parentName="ul">{`なんでもかんでも Redux に状態を管理させてしまっていないか`}</li>
      <li parentName="ul">{`無駄な再レンダリングをしていないか`}</li>
      <li parentName="ul">{`無駄な通信をしていないか`}</li>
      <li parentName="ul">{`非同期通信のレスポンスが遅い場合にサーバー側の処理に問題がないか`}</li>
      <li parentName="ul">{`関数や変数は適切な命名で適切な場所に書かれているか`}</li>
      <li parentName="ul">{`マジックナンバーを使ってしまっていないか`}
        <ul parentName="li">
          <li parentName="ul">{`使ってると修正大変`}</li>
        </ul>
      </li>
      <li parentName="ul">{`なるべく簡単に書けないか`}</li>
      <li parentName="ul">{`エラー・例外時の動きは適切か`}</li>
    </ul>
    <p>{`など細かいことを言い出したらキリがないですが、こんなこと意識してます。`}</p>
    <h1>{`よりよいものを届けるために`}</h1>
    <p>{`いろいろと書きましたが UI 設計から実装完了まではなるべく早く終わらせようと意識しています。１サイクルを`}</p>
    <ol>
      <li parentName="ol">{`UI 設計`}</li>
      <li parentName="ol">{`実装`}</li>
      <li parentName="ol">{`触る`}</li>
      <li parentName="ol">{`フィードバック`}</li>
    </ol>
    <p>{`とした場合に、`}<strong parentName="p">{`このサイクルをリリースまでに多く回すことによってより多くの気づきが得られる`}</strong>{`からです。基本的には一回作ってうまくいくことは希かと思っているからです。`}</p>
    <p>{`ただ、フィードバックの段階で本当に色々な意見がでてきます。心が折れかけることやイライラすることもあるかもしれません。ですが、プロダクトとして必要なのか、今必要なのか、対象とする利用者としてなのか、ただの愚痴なのか等を見極め軸をぶらさないことが大切です。`}</p>
    <p>{`フロントエンドの UI やパフォーマンス系は事業的に見ると優先度が低い場合が多い印象を受けます。優先度が上がるときは強い競合、数字的な上昇が見られなくなった時など危機感がでてきたときかなと。。。足りない機能を追加しないといけないし、そもそも人的リソースが足りないなどしょうがないことなのかなとも思っています。`}</p>
    <p>{`初回リリース時に時間が足りなくても精度の高いものを作りたいという気持ちがあるため先ほどのサイクルを多く回し`}<strong parentName="p">{`一人でも多くの人に触ってもらい気付ける回数を増やすことは大事`}</strong>{`なことだと思っています。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`あくまで私個人が考えてることなのでフロントエンジニア全てにあてはまるわけではありません。出来てないこと抜けてしまう時や本質的に理解していないことも多々あると思いますが、自分はこんなことを考えているという紹介でした。最後までお読みいただきありがとうございました。`}</p>
    <p>{`▼ メドレーで働くクリエイターたちのストーリーはこちら`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="MEDLEY DESIGN&ENGINEERING" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      